import * as ActionCable from '@rails/actioncable'
import encryptionService from '@/services/encryption.service'
import snackbarService from '@/services/snackbar.service'

const openWebSocketConnection = () => {
  const encryptedAuthHeaders = localStorage.getItem('authHeaders')
  let wsUrl = process.env.VUE_APP_ACTIONCABLE_URL

  if (encryptedAuthHeaders) {
    const authHeaders = JSON.parse(encryptionService.decrypt(encryptedAuthHeaders))
    wsUrl += `?access-token=${authHeaders['access-token']}&client=${authHeaders.client}&uid=${authHeaders.uid}`
  }

  return ActionCable.createConsumer(wsUrl)
}

export default {
  subscribe() {
    openWebSocketConnection().subscriptions.create({ channel: 'WebNotificationsChannel' }, {
      connected() {
        console.log('Connected to the web notification channel')
      },
      received(data) {
        if (data.status === 'success') {
          snackbarService.success(data.message)
        } else {
          snackbarService.error(data.message)
        }
      },
    })
  },
  subscribeToRunner(payload) {
    openWebSocketConnection().subscriptions.create({ channel: 'RunnerChannel' }, payload)
  },
  subscribeToReminder(payload) {
    openWebSocketConnection().subscriptions.create({ channel: 'ReminderChannel' }, payload)
  },
  subscribeToProfiles(payload) {
    openWebSocketConnection().subscriptions.create({ channel: 'ProfilesChannel' }, payload)
  },
}
