import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'
import { DirectUpload } from '@rails/activestorage'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBrokers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/brokers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCorporates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/corporates', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNiagamasLoyalties(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/niagamas_loyalties', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNiagamasMerchants(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/niagamas_merchants', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBroker(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/brokers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCorporate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/corporates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNiagamasLoyalty(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/niagamas_loyalties/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNiagamasMerchant(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/niagamas_merchants/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBroker(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/brokers/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCorporate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/corporates/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNiagamasLoyalty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/niagamas_loyalties/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNiagamasMerchant(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/niagamas_merchants/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidBroker(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/brokers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidCorporate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/corporates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidNiagamasLoyalty(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/niagamas_loyalties/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidNiagamasMerchant(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/niagamas_merchants/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createBroker(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/brokers', { broker: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCorporate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/corporates', { corporate: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createNiagamasLoyalty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/niagamas_loyalties', { niagamas_loyalty: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createNiagamasMerchant(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/niagamas_merchants', { niagamas_merchant: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    directUploadIc(ctx, file) {
      return new Promise((resolve, reject) => {
        const url = `${baseURL}/direct_uploads?referer=ic`
        const upload = new DirectUpload(file, url, {
          directUploadWillCreateBlobWithXHR: xhr => {
            const authHeaders = JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders')))
            if (authHeaders) {
              xhr.setRequestHeader('access-token', authHeaders.accessToken)
              xhr.setRequestHeader('token-type', authHeaders.tokenType)
              xhr.setRequestHeader('client', authHeaders.client)
              xhr.setRequestHeader('expiry', authHeaders.expiry)
              xhr.setRequestHeader('uid', authHeaders.uid)
            }
          },
        })

        upload.create((error, blob) => {
          if (error) reject(error)
          else resolve(blob)
        })
      })
    },
    directUploadCert(ctx, file) {
      return new Promise((resolve, reject) => {
        const url = `${baseURL}/direct_uploads?referer=cert`
        const upload = new DirectUpload(file, url, {
          directUploadWillCreateBlobWithXHR: xhr => {
            const authHeaders = JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders')))
            if (authHeaders) {
              xhr.setRequestHeader('access-token', authHeaders.accessToken)
              xhr.setRequestHeader('token-type', authHeaders.tokenType)
              xhr.setRequestHeader('client', authHeaders.client)
              xhr.setRequestHeader('expiry', authHeaders.expiry)
              xhr.setRequestHeader('uid', authHeaders.uid)
            }
          },
        })

        upload.create((error, blob) => {
          if (error) reject(error)
          else resolve(blob)
        })
      })
    },
    directUploadMerchantCert(ctx, file) {
      return new Promise((resolve, reject) => {
        const url = `${baseURL}/direct_uploads?referer=merchant_cert`
        const upload = new DirectUpload(file, url, {
          directUploadWillCreateBlobWithXHR: xhr => {
            const authHeaders = JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders')))
            if (authHeaders) {
              xhr.setRequestHeader('access-token', authHeaders.accessToken)
              xhr.setRequestHeader('token-type', authHeaders.tokenType)
              xhr.setRequestHeader('client', authHeaders.client)
              xhr.setRequestHeader('expiry', authHeaders.expiry)
              xhr.setRequestHeader('uid', authHeaders.uid)
            }
          },
        })

        upload.create((error, blob) => {
          if (error) reject(error)
          else resolve(blob)
        })
      })
    },
    fetchLoyaltyTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/loyalty_types')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendBrokerSMS(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/brokers/${params.id}/sms_send`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendLoyaltySMS(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/niagamas_loyalties/${params.id}/sms_send`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
